const blockHero = async($block) => {
	const { gsap } = await import( 'gsap' );
	const { ScrollTrigger } = await import( 'gsap/ScrollTrigger' );
	gsap.registerPlugin( ScrollTrigger );

	if ($('.wp-site-blocks').length) {
		gsap.to( $block.find('.background-img'), {
			y: '50%',
			ease: 'none',
			scrollTrigger: {
				scroller: '.wp-site-blocks',
				trigger: $block,
				start: 'top top',
				end: 'bottom top',
				scrub: true
			}
		} );

		function lazyLoadVideo() {
			let video = $( '.section-hero__bg video' );
			if ( video.length === 0 ) {
				return;
			}
			let source = video.find('source');

			let posterImage = new Image();
			posterImage.src = video.attr('data-poster');

			let dataSrc = source.attr('data-src');
			source.attr('src', dataSrc);

			video[0].load();
		}

		function lazyLoadPoster() {
			let video = $('.section-hero__bg video');
			if ( video.length === 0 || !video.attr('data-poster') ) {
				return;
			}

			let posterImage = new Image();
			posterImage.src = video.attr('data-poster');

			posterImage.onload = function() {
				video[0].poster = posterImage.src;
			};

			posterImage.src = video.attr('data-poster');
		}

		$( window ).on( 'load', function() {
			lazyLoadVideo();
			lazyLoadPoster();
		});
	}
};

themeUtils.loadBlock( blockHero, 'hero', '.section-hero' );
